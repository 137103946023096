import React, { useState ,useRef} from "react";
import {
  LinearProgress,
  OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem,
  TableRow, Table,
  TableHead,
  TableBody,
  TableCell
} from "@material-ui/core";
import ExbhitionService from "./Locality/Service/exbhitionService";
import ParkService from "./Locality/Service/parkService";
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ProfileRegistrationService from "./Locality/Service/profileRegistrationService"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Alert from '@material-ui/lab/Alert';
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import { withStyles } from '@material-ui/core/styles';

export default function ExbhitionAppointment(props) {
    const socket = useRef();
    const { bookingId, exbhitionId } = useParams();
    const [ticketBooking, setTicketBookingList] = useState({ "status": 'info',
    "image": "",
    "message": "",
    "organizationName":'',
    "visitorName": "",
    "exbhitionId":"",
    "id": ""});
 
    const formatURL =(urlString)=>{

const url = new URL(urlString);
const hostname = url.hostname;

return hostname;
    }
  useEffect(() => {
    getByIdList();
   
    const url = formatURL(process.env.REACT_APP_APIURL);
    console.log(url) 
    socket.current = io("https://dev-sriqrapi.onrender.com");

    socket.current.on("connnection", () => {
      console.log("connected to server");
    });

    socket.current.on("message", (msg) => {
      console.log("message to server"+msg);
      getByIdList();
    });

    return () => {
     
    };
}, []);


const handleRedirect = () => {
    props.history.push("/exbhitionbooking/" + ticketBooking.exbhitionId+"/"+ticketBooking.exbhitionName)
};
const getQRCode = () => {
        
  props.history.push("/getqr/" + exbhitionId)
};

const getByIdList = () => {

  console.log(bookingId);
  console.log(exbhitionId);
  ExbhitionService.getAppointmentDetailsById({bookingId:bookingId}).then((res) => {
      if (res) {
        res.status = 'success';
        setTicketBookingList(res);
        if(res.status){

        }else{
            setTicketBookingList( { "status": 'error',
            "image": "",
            "organizationName": "",
            "visitorName": "",
    "exbhitionId":"",
            "message": res.message,
            "id": ""})
        }
        

      }else{
        setTicketBookingList( { "status": 'error',
    "image": "",
    "branchName": "",
    "visitorName": "",
    "exbhitionId":"",

    "message": res.message,
    "id": ""})
      }



    }).catch((err) => {
     // setError(err.message);
    });
  }
  return (

    <>
<div >
<Grid  container  spacing={3}
  direction="column"
  alignItems="center">
<Card style={{width:"800px"}} >

<CardHeader/>
<CardContent>
   <div style={{
    'text-align': 'center', 
    
}}>
   {/* <Alert severity="info" style={{fontSize:"18px"}}> Appointment is booked with Dr. {ticketBooking?.doctorName} And  your Token number is  <span style={{fontSize:"25px"}}> {ticketBooking?.tokenNumber}</span>. 
  And currently runnning token number is <span style={{fontSize:"25px"}}>{ticketBooking?.currentRunningCount}</span></Alert> */}
  <div  >

  <Alert severity="info" style={{fontSize:"18px"}}>Thanks for your visit, We would be delighted to be in touch with you</Alert>
  </div>
  
  <br></br>
<div class="button wow fadeInLeft" data-wow-delay=".8s">

{/* <a href="javascript:void(0)" class="btn" onClick={handleRedirect}><i class="lni lni-apple"></i> Book More Appointments</a> */}

{/* <a href="javascript:void(0)" class="btn btn-alt"><i class="lni lni-play-store"></i> Google
Play</a> */}
</div>
   </div>

  
  
</CardContent>


</Card>
</Grid>


    </div>
          
    </>
  );
}


