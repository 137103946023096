import React, { useState } from "react";
import {
    Button, FormControl, InputLabel, MenuItem, TableRow, Table, TableHead,
    TableBody, TableCell
} from "@material-ui/core";
import ExbhitionService from "../dashboard/Locality/Service/exbhitionService";
import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useEffect } from 'react';
import exbhitionBookingService from "./Locality/Service/exbhitionBookingService";
import TablePagination from '@material-ui/core/TablePagination';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ExportExcel from "../../Excelexport";
import Loader from './Loader';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(255, 107, 129)",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function ShowReport() {
    
    const classes = useStyles();
    const [organizationId, setOrganizationId] = useState("");
    const [result, setResult] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [pg, setpg] = React.useState(0);
    const [organizationList, setOrganizationList] = useState([]);
    const [rpg, setrpg] = React.useState(5);
    const [startDate1, setStartDate1] = useState('');
    const [endDate1, setEndDate1] = useState('');
    const [age, setAge] = React.useState('');
    const [getReport, setGetReport] = useState([]);
    const [open, setOpen] = React.useState(false);
    const current = new Date();
    const date = `${current.getFullYear()}-0${current.getMonth() + 1}-${current.getDate()}`;
   
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProfileId();
        
        return () => {
            setReportList([]);          
            setGetReport([]);
        }
    }, []);
    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    const getProfileId = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        console.log(userDetails.role)

        const result = userDetails.role === "admin" ? null : userDetails.profileId;
        setLoading(true);
        ExbhitionService.addAllProfileId({ profileRegistrationId: result }).then((res) => {
            if (res.length > 0) {
                setOrganizationList(res);
                const firstValue = res[0];
                setOrganizationId(firstValue._id);
                onSubmit(firstValue._id);
                
            } else {
                setOrganizationList(res);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            // setError(err.message);
        });
    }
  

   
    const onSubmit = data => {
        const report = JSON.parse(localStorage.getItem("userDetail"));
        let newstartDate1 = startDate1 ? startDate1 : null;
        let newendDate1 = endDate1 ? endDate1 : null;

        const keys = { "organizationId": data ? data : organizationId, "startDate": newstartDate1, "endDate": newendDate1 ? `${newendDate1}`:'' }
        setLoading(true);
        exbhitionBookingService.getExbhitionBookingList(keys).then((res) => {
           debugger
            if (res.length) {
                 excelExport(res);
                          } else {
            }
            setLoading(false);
             setResult(res);
        }).catch((err) => {
            setLoading(false);
        });
    };

    const  excelExport  =(res)  => {
        const result= res.map((response)=>{
 
            return {
               
                "Visitor Name":response.visitorName,
                "Mobile":response.mobile,
               
            }
        })
        setResult(result);
     };
    const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }
    return (
        <>
            <PageTitle title="Reports" />
            <Card sx={{ maxWidth: 345 }}>
                <Box   >
                    <div >
                        <form >
                            <Grid container spacing={2} columns={12} style={{ margin: 10 }}  >
                                <Grid item xs={6} sm={6} md={2} >
                                    <FormControl className={classes.formControl} fullWidth="true"
                                    >
                                        <InputLabel id="demo-simple-select-label">Select Show</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="organizationId"
                                            label="Select Show"
                                            value={organizationId}
                                            onChange={(e) => setOrganizationId(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {organizationList.map(({ _id, organizationName }) => (
                                                <MenuItem key={_id} value={_id}>{organizationName}

                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                    <form className={classes.container} noValidate>
                                        <TextField InputProps={{ style: { width: 150 } }}
                                            id="dob"
                                            name="dob"
                                            label="Start Date"
                                            type="date"
                                            min="2016-11-10"
                                            max="2022-11-10"
                                            value={startDate1}
                                            onChange={e => { setStartDate1(e.target.value) }}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </form>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} >
                                    <TextField InputProps={{ style: { width: 120 } }}
                                        id="dob"
                                        name="dob"
                                        label="End Date"
                                        type="date"
                                        min="2016-11-10"
                                        max="2022-11-10"
                                        value={endDate1}
                                        onChange={e => { setEndDate1(e.target.value) }}
                                        // defaultValue="2017-05-24"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} >
                                    <Button style={{ backgroundColor: 'rgb(255 107 129)', color: 'white' }}
                                        type="button" onClick={() => onSubmit()} variant="contained" >
                                        Search</Button>
                                </Grid>
                                
                                <Grid item xs={6} sm={6} md={2}  >
                                <ExportExcel   excelData={result} fileName={'Reports'} />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Box>
            </Card>
            {loading && <Loader />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                    {/* <h6>Grand Total: ₹ {grandTotal}</h6> */}
                    <br></br>
                        {result.length > 0 ? (
                            <Table className="mb-0">
                                <TableHead >
                                    <TableRow>
                                    <StyledTableCell>Visitor Name</StyledTableCell>
                                    <StyledTableCell>Mobile</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {result.slice(pg * rpg, pg * rpg + rpg).map((report) => (
                                        <TableRow key={report._id}>


<TableCell className="pl-3 fw-normal" >{report.visitorName}</TableCell>

                                                <TableCell className="pl-3 fw-normal" >{report.mobile}</TableCell>

                                         

                                           
                                        </TableRow>

                                    ))}
                                    {/* ))} */}
                                </TableBody>
                      
                            </Table>
                        ) : (
                            <p style={{ textAlign: "center" }}> No data to display.</p>
                        )}

                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 1000, 100000]}
                            count={reportList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>
        </>
    );
}


