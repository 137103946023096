import React, { useState } from "react";
import { Button, Card, Box } from "@material-ui/core";
import ParkService from "./Locality/Service/parkService";
import { Grid, TextField } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import AdvertisementBanner from "../../components/AdvertisementBanner/AdvertisementBanner"
import GoogleAd from "./GoogleAd";
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { useParams } from "react-router-dom";
import TicketBookingService from "./Locality/Service/ticketBookingService";
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PaymentIcon from '@material-ui/icons/Payment';
import Footer from "../../pages/home/Footer"
import Loader from './Loader';
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
    color: 'rgb(255, 107, 129)',
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },

});

export default function TicketBooking(props) {
  const classes = useStyles();
  const [mid, setMid] = useState(null);
    const [advtDetails, setAdvtDetails] = useState([]);

  
  const [loading, setLoading] = useState(false);
  const [ticketBooking, setTicketBooking] = useState({
    parkName: '',
    payment:'',
  });
  const { parkId } = useParams();
  const [mobile, setMobile] = useState('');
  const [ticketBookingList, setTicketBookingList] = useState([]);
  const [profileRegistrationId, setProfileRegistrationId] = useState('');
  var [error, setError] = useState(null);
  const [fee, setFee] = useState([]);
  const handleIncrement = (id,name) => {
    if(name.name === 'Family')
    {
      if(name.quantity>=1){

        alert("Only one Family ticket can be purchased per transaction.")
        return;
      }

    }
    if(name.name === 'Family Addon'){
      const familyItem = fee.find(item => item.name === "Family");
      if(familyItem.quantity<1){
        alert("Family Addon is an additional item. Please add a Family ticket before selecting the Addon.")
        return;
      }
    }
    setFee((prevItems) =>{
     return prevItems.map((item) =>{
      return item.id === id ? { ...item, quantity: item.quantity + 1, totalprice: (item.price * (item.quantity + 1)) } : item
     }
      )
    }
     
    );
   
    // sumOfTotal();
  };
  const handleDecrement = (id,item) => {
    
    if (item.name === 'Family' && item.quantity <= 1) {
      const familyItemIndex = fee.findIndex(item => item.name === "Family Addon");
      setFee(prevItems =>
        prevItems.map((currentItem, index) =>
          index === familyItemIndex
            ? { ...currentItem, quantity: 0 }
            : currentItem
        )
      );
    }
    setFee(prevItems =>
      prevItems.map(item =>
        item.id === id && item.quantity > 0
          ? { ...item, quantity: item.quantity - 1, totalprice: (item.price * (item.quantity - 1)) }
          : item
      )
    );

  };
  // useEffect(() => {
  //     const script = document.createElement('script');
  //     script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2277440900291589";
  //     script.async = true;
  //     script.crossOrigin = "anonymous";
  //     document.body.appendChild(script);
  //     return () => {
  //       document.body.removeChild(script);
       
  //     };
  //   }, []);


  const getDay = () => {
    const currentDate = new Date();
    const currentDayNumber = currentDate.getDay();

    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const currentDayName = daysOfWeek[currentDayNumber];
    return currentDayName;
  }

  const handleOpens = () => {
    props.history.push(`/getqr/${parkId}`)
  };
  const sumOfTotal = () => {
   
    const totalPrice = fee.reduce((sum, item) => sum + (item.price * item.quantity), 0);
 
  }


  const Item = ({ name, price, quantity, onIncrement, onDecrement }) => {
    return (
      <>
       
       
        <Grid item xs={4}>

          <div>
            <h4 style={{
              'overflow': 'hidden',
              'text-transform': 'uppercase',
              'text-overflow': 'ellipsis',
              'white-space': 'break-spaces'
            }}>{name}</h4>

          </div>
          <div>
            <span> &#x20b9;{price}</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ textAlign: 'center' }}>
            <RemoveIcon style={{ cursor: 'pointer', width: "22px", height: "22px", }} onClick={onDecrement} />
            <span style={{ fontSize: '25px', padding: '5px' }}>{quantity}</span>
            <AddIcon style={{ cursor: 'pointer', width: "22px", height: "22px", }} onClick={onIncrement} />
          </div>

          {/* <div>
            <h4> &#x20b9;{price * quantity}</h4>
          </div> */}
        </Grid>
        <Grid item xs={4}>


          <div style={{ textAlign: 'end' }}>
            <h4> &#x20b9;{price * quantity}</h4>
          </div>
        </Grid>
      


      </>



    );
  };
  const pricePerItem = 10;
  useEffect(() => {
    getByIdList();
    getAdvByIdList();

    return () => {
      setAdvtDetails([])
    }
  }, []);
  const getAdvByIdList = () => {
    const hardcodedData = {
      parkId: parkId,
      where: "Booking Page" // Correctly structured object
  };
    ParkService.getAdvbyById(hardcodedData).then((res) => {
        if (res) {
            setAdvtDetails(res.advatagement);

        } 



    }).catch((err) => {
        // setError(err.message);
    });
}
  useEffect(() => {
    // Step 2: Load Paytm script dynamically after MID is fetched
    if (mid) {
      const script = document.createElement("script");
      script.type = "application/javascript";
      script.src = `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/${mid}.js`;
      script.crossOrigin = "anonymous";
     // script.onload = () => onScriptLoad();
      document.body.appendChild(script);

      // Clean up the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [mid]);
 
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  function isObj(val) {
    return typeof val === 'object'
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })

    return form
  }
  function post(details) {
    const form = buildForm(details)
 
    document.body.appendChild(form)
    form.submit()
    form.remove()
    //setLoading(false);
  }
  const payment = (values) => {
    TicketBookingService.paymentProcess(values).then((response) => {
      var information = {
        action: process.env.REACT_APP_CCAV_URL,
        params: response
      }

      post(information);

    })
  }
  const paytmPayment = (values) => {
    TicketBookingService.paytmPaymentProcess(values).then((response) => {
      var information = {
        action: process.env.REACT_APP_CCAV_URL,
        params: response
      }
      
      if(response.body.resultInfo.resultStatus === "S"){
        const token = response.body.txnToken;
        postPaytm(token,values);

      }
     // post(information);

    })
  }
  const postPaytm =(token,values)=>{
    var config = {
      "root": "",
      "flow": "DEFAULT",
      "data": {
      "orderId": values.id, /* update order id */
      "token": token, /* update token value */
      "tokenType": "TXN_TOKEN",
      "amount": values.amount /* update amount */
      },
      "handler": {
      "notifyMerchant": function(eventName,data){
      console.log("notifyMerchant handler function called");
      console.log("eventName => ",eventName);
      if(eventName === 'APP_CLOSED'){
        setLoading(false);
      }
    //  console.log("data => ",data);
      }
      }
      };
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)
          .then(() => {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch((error) => console.error("Paytm error:", error));
      }
  }
  const bookTickets = () => {


    const getBookedDetails = fee.filter(feeDetails => feeDetails.quantity > 0);
    const totalSum = getBookedDetails.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const day = getDay();
    const phoneNumberRegex = /^\d{10}$/;


    if (!mobile) {
      alert("Please enter mobile number");
      return
    }
    if (!phoneNumberRegex.test(mobile)) {
      alert("Please enter valid mobile number");
      return
    }
    if (ticketBooking.holidayDays && ticketBooking.holidayDays.includes(day)) {
      alert("You cant procced to book the ticket. today is holiday ");
      return
    }
    if (getBookedDetails.length <= 0) {
      alert("You need to select atleast one field");
      return
    }

    const ticketDetails = {
      fee: getBookedDetails,
      totalAmount: totalSum,
      parkId: parkId,
      parkName: ticketBooking.parkName,
      mobile: mobile,
      paymentStatus: "pending",
      profileRegistrationId: profileRegistrationId,
      useragent:navigator.userAgent,
      paymentVendor:ticketBooking.payment
    }
    setLoading(true);
    TicketBookingService.creteTicketBooking(ticketDetails).then((res) => {

      setMobile('');
      if(ticketBooking.payment === 'paytm'){
        paytmPayment({ amount: totalSum, mobile: mobile, id: res.id,mid:ticketBooking.mid,parkId:parkId })

      }else{
        payment({ amount: totalSum, mobile: mobile, id: res.id,mid:ticketBooking.mid,parkId:parkId })

      }

    })
      .catch((err) => {
setLoading(false);
        alert(err.response.data.message)

      })
  }

  const updateMobile = (event) => {
    setMobile(event.target.value)
  }

  const getByIdList = () => {
    setLoading(true);
    ParkService.getParkById(parkId).then((res) => {
      const park = [];
      if (res) {

        setLoading(false);
        setProfileRegistrationId(res.profileRegistrationId);

        Object.keys(res).map(key => {
          if (key === "seniorCitizen" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['seniorCitizenFee']), quantity: 0, name: 'Senior Citizen' ,isShow:true},
            )
          } else if (key === "women" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['womenFee']), quantity: 0, name: 'Women' ,isShow:true},
            )
          } else if (key === "physicallyChallenged" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['physicallyChallengedFee']), quantity: 0, name: 'Physically Challenged' ,isShow:true},
            )
          } else if (key === "camera" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['cameraFee']), quantity: 0, name: 'Camera' ,isShow:true},
            )
          }
          else if (key === "photography" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['photographyFee']), quantity: 0, name: 'Photography',isShow:true },
            )
          } else if (key === "shooting" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['shootingFee']), quantity: 0, name: 'shooting',isShow:true },
            )
          }
          else if (key === "walker" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['walkerFee']), quantity: 0, name: 'walker',isShow:true },
            )
          } else if (key === "adult" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['adultFee']), quantity: 0, name: 'adult',isShow:true },
            )
          }
          else if (key === "ticket" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['ticketFee']), quantity: 0, name: 'ticket',isShow:true },
            )
          }
           else if (key === "child" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['childFee']), quantity: 0, name: 'child',isShow:true },
            )
          } else if (key === "fourwheeler" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['fourwheelerFee']), quantity: 0, name: 'Four Wheeler',isShow:true },
            )
          } else if (key === "twowheeler" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['twowheelerFee']), quantity: 0, name: 'Two Wheeler',isShow:true },
            )
          }
          else if (key === "family" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['familyFee']), quantity: 0, name: 'Family' ,isShow:true},
            )
          }
          else if (key === "familyAddon" && res[key]) {
            park.push(
              { id: getRandomInt(1000, 10000), price: Number(res['familyAddonFee']), quantity: 0, name: 'Family Addon' ,isShow:false},
            )
          }
        

        }



        )

        setFee(park);
        setTicketBooking(res);
        if(res.payment){
          setMid(res.mid);
        }

      }



    }).catch((err) => {
      setError(err.message);
    });
  }

  const formik = useFormik({
    initialValues: ticketBooking,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      //   SchooleRegistrationService.creteSchooleRegistration(values).then((res) => {

      //     alert(" Registration Successfully.");
      //     props.history.push('/montessori/login');
      //   })
      //     .catch((err) => {
      //       alert(err.response.data.message);
      //     })
    },
  });

  const totalSum = fee.reduce((sum, item) => sum + item.price * item.quantity, 0);

  return (
    <>
 {loading ? <Loader />:


      <Box   >
        <Grid container
          spacing={0}
          direction="column"
          alignItems="center"

          style={{ minHeight: '100vh' }}>
          <form onSubmit={formik.handleSubmit} class="form" style={{ width: '100%', margin: '15px' }} >
            <div >
              <Card >
                <CardHeader
                  avatar={

                    <LocationOnIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                  }


                  // action={
                  //   <IconButton aria-label="settings">

                  //   </IconButton>
                  // }
                  // titleTypographyProps={{variant:'h6' }}

                  classes={{
                    title: classes.title
                  }}
                  title={ticketBooking?.parkName}
                  // <span style={{fontWeight:"600", fontSize:"x-large", color:'purple'}}>
                  
                  // </span>
                />
                {/* <Grid item xs={12}>
                    <PageTitle title="Ticket Booking" />
                  </Grid> */}
                <CardContent>
                  {(ticketBooking?.isCloakRoom || ticketBooking?.isHolidays || ticketBooking?.holidayDays ) ? <Grid item xs={12}>
                    <h4> Note: <span style={{
                      'color': "#0a88e4",
                      'font-size': "13px"
                    }}>
                      {ticketBooking?.isCloakRoom ? <span>1.Cloak room Avilable</span> : ''},{ticketBooking?.isHolidays ? <span>2.Holidays on {ticketBooking?.holidayDays}</span> : ''}
                      
                    </span></h4>

                  </Grid> : ''}
                  {(ticketBooking?.note && ticketBooking?.note !== "") ? <Grid item xs={12}>
                    <h4> NOTE: <span style={{
                      'color': "#0a88e4",
                      'font-size': "15px",
                      'margin-bottom':'10px',
                      'text-transform':'uppercase',
                      
                    }}>
                      {ticketBooking?.note }
                      
                    </span></h4>

                  </Grid> : ''}

                  <Grid item xs={12} >
                    {/* <TextField

                      margin="dense"
                      id="parkName"
                      name="parkName"
                      value={ticketBooking.parkName}
                      // label=" Name of the entity"
                      label={<span style={{ fontWeight: '600' }}>Name of the entity</span>}

                      type="text"
                      variant="outlined"
                      style={{ width: '100%' }}
                      
                    /> */}
                    {/* <span style="color:blue">blue</span> */}
                    {/* <span style={{fontWeight:"600", fontSize:"x-large", color:'purple'}}>
                    {ticketBooking?.parkName}
                    </span> */}
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField autoFocus
                      style={{ width: '100%' }}
                      margin="dense"
                      id="mobile"
                      name="mobile"
                      value={mobile}
                      onChange={updateMobile}
                      label="Enter Mobile Number"
                      type="text"
                      variant="outlined"

                    />
                  </Grid>
                </CardContent>


              </Card>
            </div>
            <div style={{ marginTop: "2%" }}>
              <Card >

                <CardHeader
                  avatar={

                    <ConfirmationNumberIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                  }
                  classes={{
                    title: classes.title
                  }}
                  title="Number of tickets"

                />
                <CardContent>
                  <Grid container >
                    {fee.map(item => (
                   <Item
                        key={item.id}
                        name={item.name}
                        price={item.price}
                        quantity={item.quantity}
                        onIncrement={() => handleIncrement(item.id,item)}
                        onDecrement={() => handleDecrement(item.id,item)}
                      />
                    ))}
                  </Grid>
                </CardContent>


              </Card>
            </div>
            <div style={{ marginTop: "2%" }}>
              <Card >

                <CardHeader
                  avatar={

                    <PaymentIcon style={{ color: 'rgb(255, 107, 129)', fontSize: 25 }} />

                  }
                  classes={{
                    title: classes.title
                  }}
                  title="Amount to be paid"

                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <h4> Sub Total:</h4>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ textAlign: 'end' }}>
                        <h3>  &#x20b9;  {totalSum}.00</h3>
                      </div>

                    </Grid>
                  </Grid>



                </CardContent>
              </Card>
            </div>
            <div style={{ marginTop: "2%" }}>
              <Grid item xs={12}>
                <div style={{ textAlign: 'left' }}>
                  {/* <a href="javascript:void(0)" class="btn" ><i class="lni lni-apple"></i> Book More Tickets</a> */}
                  {/* <PublicRoute path="/ticketbooking/:parkId" component={TicketBooking} /> */}

                  <a style={{ fontWeight: 'bold', color: '#ff6b81', fontSize: 'large' }} href="javascript:void(0)" onClick={handleOpens} class="page-scroll active">
                     Get QR </a>
                  <p>(In case QR not generated after payment, click here)</p>
                </div>
              </Grid>
            </div>
            <Grid item xs={12}>
            <div style={{ marginTop: "2%" }}>
             
                <div style={{ textAlign: 'center' }}>
                  <Button style={{ backgroundColor: 'rgb(255, 107, 129)', color: 'white', marginBottom: '20px' }} onClick={bookTickets} variant="contained" >Book</Button>
                </div>
            
            </div>
            </Grid>
          </form>
          
        </Grid>
        <div id="ad-container" style={{ margin: "20px 0" }}>
        <GoogleAd />
      </div>
        {advtDetails.map(({ advatagementName,imgURL,urlLink,desc },index) => (

<AdvertisementBanner key={index}
imageUrl={imgURL.length ? imgURL[0]:''}
heading={advatagementName}
description={desc}
buttonText="Shop Now"
buttonUrl={urlLink}
/>

))}
        {/* <Footer link="https://www.ccavenue.com/terms_conditions.jsp#:~:text=Subject%20to%20the%20terms%20of,handling%20fee%2C%20express%20payout%20charges%2C"></Footer> */}
        <Footer color="primary" className={classes.copyright} link='/#/privacypolicy'>
            </Footer>
      </Box>}
     
{/* <Footer link="https://www.ccavenue.com/terms_conditions.jsp#:~:text=Subject%20to%20the%20terms%20of,handling%20fee%2C%20express%20payout%20charges%2C"></Footer> */}
    </>
  );
}


