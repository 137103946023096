// import React from "react";
// import { withRouter } from "react-router-dom";
// import SRI from './images/SRI.jpg';
// import './styles/bootstrap.min.css';
// import './styles/animate.css';
// import './styles/tiny-slider.css';
// import './styles/glightbox.min.css';
// import './styles/main.css';
// function Header(props) {
//   const [isOpen, setIsOpen] = React.useState(false);
//   const toggleNavbar = () => {
//     setIsOpen(!isOpen);
//   };
//   const handleOpen = () => {
//     props.history.push('/sriqr/login') 
// };
// const handleChange=()=>{
//     props.history.push('/sriqr/profileregistration') 
// }
// const handleLogin=()=>{
//     props.history.push('/sriqr/branchuser') 
// }
// const handleUser=()=>{
//     props.history.push('/sriqr/inspectorlogin') 
// }
//   return (
  
//     <>
//     <header class="header navbar-area sticky">
//         <div class="container">
//             <div class="row align-items-center">
//                 <div class="col-lg-12">
//                     <div class="nav-inner">
                       
//                         <nav class="navbar navbar-expand-lg">
//                             <a class="navbar-brand" href="index.html">
//                                 {/* <img src="assets/images/logo/white-logo.svg" alt="Logo"/> */}
//                                 <img src={SRI} alt="some example image" style={{height:"70px", width:"70px"}}/>
//                                 {/* <img src="home/images/SRIQR.COM LOGO JPG.jpg" alt="Sriqr.com"/> */}

//                             </a>
//                             <button class="navbar-toggler mobile-menu-btn" onClick={toggleNavbar} type="button" data-bs-toggle="collapse"
//                                 data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
//                                 aria-expanded="false" aria-label="Toggle navigation">
//                                 <span class="toggler-icon"></span>
//                                 <span class="toggler-icon"></span>
//                                 <span class="toggler-icon"></span>
//                             </button>
//                             {isOpen? <div class=" navbar-collapse sub-menu-bar" id="navbarSupportedContent">
//                                         <ul id="nav" class="navbar-nav ms-auto">
//                                             <li class="nav-item">
//                                                 <a href="#home" class="page-scroll active"
//                                                     aria-label="Toggle navigation">Home</a>
//                                             </li>
//                                             <li class="nav-item">
//                                 <a href="javascript:void(0)" onClick={handleOpen} class="page-scroll active"> Client Login</a>
//                             </li>
//                             <li class="nav-item">
//                                                 <a href="javascript:void(0)" onClick={handleChange}  class="page-scroll active"
//                                                     aria-label="Toggle navigation"> Profile Registration</a>
//                                             </li>
//                                             <li class="nav-item">
//                                                 <a href="javascript:void(0)" onClick={handleUser}  class="page-scroll active"
//                                                     aria-label="Toggle navigation">Ticket Inspector</a>
//                                             </li>
                                            
                            
//                                         </ul>
//                                     </div>:<div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
//                                         <ul id="nav" class="navbar-nav ms-auto">
//                                             <div class="nav-item">
//                                                 <a href="#home" class="page-scroll active"
//                                                     aria-label="Toggle navigation">Home</a>
//                                             </div>
//                                             <div class="nav-item">
//                                             <a href="javascript:void(0)" onClick={handleOpen} class="page-scroll active"> Client Login</a>
//                                             </div>
//                                             <div class="nav-item">
//                                                 <a href="javascript:void(0)" onClick={handleChange} class="page-scroll active"
//                                                     aria-label="Toggle navigation">Profile Registration</a>
//                                             </div>
//                                             <div class="nav-item">
//                                                 <a href="javascript:void(0)" onClick={handleLogin} class="page-scroll active"
//                                                     aria-label="Toggle navigation">Counter Login</a>
//                                             </div>
//                                             <div class="nav-item">
//                                                 <a href="javascript:void(0)" onClick={handleUser} class="page-scroll active"
//                                                     aria-label="Toggle navigation">Ticket Inspector</a>
//                                             </div>
//                                         </ul>
//                                     </div>}
//                             {/* <div class="button add-list-button">
//                                 <a href="javascript:void(0)" onClick={handleOpen} class="btn">gg Admin Login</a>
//                             </div> */}
//                         </nav>
                        
//                     </div>
//                 </div>
//             </div> 
//         </div> 
//     </header>
//     </>
//   );
// }

// export default withRouter(Header);
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SRI from './images/SRI.jpg';
import './styles/bootstrap.min.css';
import './styles/animate.css';
import './styles/tiny-slider.css';
import './styles/glightbox.min.css';
import './styles/main.css';

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleOpen = () => {
    props.history.push('/sriqr/login');
  };

  const handleChange = () => {
    props.history.push('/sriqr/profileregistration');
  };

  const handleLogin = () => {
    props.history.push('/sriqr/branchuser');
  };

  const handleUser = () => {
    props.history.push('/sriqr/inspectorlogin');
  };

  // Dynamically load the script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2277440900291589";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <header className="header navbar-area sticky">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="index.html">
                    <img src={SRI} alt="some example image" style={{ height: "70px", width: "70px" }} />
                  </a>
                  <button
                    className="navbar-toggler mobile-menu-btn"
                    onClick={toggleNavbar}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  {isOpen ? (
                    <div className="navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                      <ul id="nav" className="navbar-nav ms-auto">
                        <li className="nav-item">
                          <a href="#home" className="page-scroll active" aria-label="Toggle navigation">Home</a>
                        </li>
                        <li className="nav-item">
                          <a href="javascript:void(0)" onClick={handleOpen} className="page-scroll active">Client Login</a>
                        </li>
                        <li className="nav-item">
                          <a href="javascript:void(0)" onClick={handleChange} className="page-scroll active" aria-label="Toggle navigation">Profile Registration</a>
                        </li>
                        <li className="nav-item">
                          <a href="javascript:void(0)" onClick={handleUser} className="page-scroll active" aria-label="Toggle navigation">Ticket Inspector</a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                      <ul id="nav" className="navbar-nav ms-auto">
                        <div className="nav-item">
                          <a href="#home" className="page-scroll active" aria-label="Toggle navigation">Home</a>
                        </div>
                        <div className="nav-item">
                          <a href="javascript:void(0)" onClick={handleOpen} className="page-scroll active">Client Login</a>
                        </div>
                        <div className="nav-item">
                          <a href="javascript:void(0)" onClick={handleChange} className="page-scroll active" aria-label="Toggle navigation">Profile Registration</a>
                        </div>
                        <div className="nav-item">
                          <a href="javascript:void(0)" onClick={handleLogin} className="page-scroll active" aria-label="Toggle navigation">Counter Login</a>
                        </div>
                        <div className="nav-item">
                          <a href="javascript:void(0)" onClick={handleUser} className="page-scroll active" aria-label="Toggle navigation">Ticket Inspector</a>
                        </div>
                      </ul>
                    </div>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default withRouter(Header);
