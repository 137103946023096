import React, { useEffect } from "react";

const GoogleAd = () => {
  useEffect(() => {
    // Create the script element for Google AdSense
    const script = document.createElement("script");
    script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1234&host=ca-host-pub-5678";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.head.appendChild(script);

    // Once the script is loaded, initialize the ad
    script.onload = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        console.error("Error initializing Google AdSense:", error);
      }
    };

    // Optionally, clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-1234"
      data-ad-host="ca-host-pub-5678"
      data-ad-format="auto"
    ></ins>
  );
};

export default GoogleAd;